import ReactGA from "react-ga4";

const condition =
  process.env.REACT_APP_GOOGLE_ANALYTICS_TOGGLE &&
  !!process.env.REACT_APP_GOOGLE_ANALYTICS;

const executeIfCondition = (fn) => {
  return (...args) => {
    if (condition) {
      return fn(...args);
    }
  };
};

export const googleAnalyticsInit = executeIfCondition(() => {
  return ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS);
});

export const eventPickupScheduled = executeIfCondition(() => {
  return ReactGA.event({
    category: "Pickup",
    action: "scheduled",
  });
});

export const eventCustome = executeIfCondition((category, action) => {
  return ReactGA.event({
    category,
    action,
  });
});
