import moment from "moment";
import request from "api/request";

/*
 *
 *  Phone Numbers
 *
 * */
export const formatPhone = (phone = "") => {
  if (phone) {
    let numbers = phone.replace(/\D/g, ""),
      formattedNumber = "",
      char = {
        0: "(",
        3: ") ",
        6: "-",
      };
    // preventing the phone number input from being longer than 10 digits
    if (numbers.length > 10) {
      // grabbing the first ten digits of the phone number
      const firstTenDigits = numbers.slice(0, 10);
      numbers = firstTenDigits;
    }

    for (var i = 0; i < numbers.length; i++) {
      formattedNumber += (char[i] || "") + numbers[i];
    }

    return formattedNumber;
  }
};

export const phoneNormalize = (phone = "") =>
  phone.replace(/^\+1/g, "").replace(/\D/g, "");

//@ 1st Param is the phoneNumbers property of the 'values' object, i.e. values.phoneNumbers
//@ 2nd Param is the 'errors' object to which the flag should be attributed
//! This method will provide an error for both an empty field and insufficient length
//@ Utilizes the 'validate' object from redux-forms to access form values
//@ This method can be globally applied to any form where this field is required

export const phoneFieldValidate = (errors, values) => {
  if (!values.phoneNumber) {
    errors.phoneNumber = "This field can’t be left blank.";
    return;
  }

  if (values.phoneNumber.length !== 10) {
    errors.phoneNumber = "Must be valid 10 digit Phone Number";
    return;
  }
};

export const phoneFieldLength = (errors, values) => {
  if (!values.phoneNumbers || !values.phoneNumbers.length) {
    errors.phoneNumbers = { _error: "Please enter at least one phone number." };
  } else {
    const phoneNumbersArrayErrors = [];
    values.phoneNumbers.forEach((phoneNumber, numberIndex) => {
      const phoneErrors = {};
      if (!phoneNumber || !phoneNumber.number) {
        phoneErrors.number = "This field can’t be left blank.";
      } else if (phoneNumber.number.length !== 10) {
        phoneErrors.number = "Must be valid 10 digit Phone Number";
      }
      if (Object.keys(phoneErrors).length > 0) {
        phoneNumbersArrayErrors[numberIndex] = phoneErrors;
      }
      errors.phoneNumbers = phoneNumbersArrayErrors;
    });
  }
  // yellowPages.forEach(phone => {

  //     if(phone.number.length === 0) {
  //       return  errors.phoneNumbers['number'] === 'This field is required'
  //     } else if (phone.number.length < 10) {
  //       return  errors.phoneNumbers['number'] === 'Please provide valid, 10 digit number'
  //     }

  // })
};

/*
 *
 * Date Helpers
 *
 *
 * */

export const getStartOfDayString = (newDate) => {
  return moment(newDate).startOf("day").toISOString();
};

/*
 *
 * Empty Field Helper(s)
 *
 * */

//@ 1st Param is the 'errors' object to which the flag should be attributed
//@ 2nd Param is a reference to the 'values' object which holds field values
//@ 3rd Param is the selected fields (i.e.: fname, lname, email etc.)
//@ Utilizes the 'validate' object from redux-forms to access form values
//@ This method can be globally applied to any form where this field is required

export const validateEmpty = (errorList, values, arrayOfFields) => {
  // let args = Array.prototype.slice.call(arguments, 1);
  // console.log('args: ', args)

  arrayOfFields.forEach((field) => {
    if (!values[field]) {
      return (errorList[field] = "Required");
    }
  });
};

/*
 *
 * Name Helpers
 *
 * */

//@ 1st Param is the 'errors' object to which the flag should be attributed
//@ 2nd Param is a reference to the 'values' object which holds field values
//@ 3rd Param is the selected field (i.e.: fname, lname etc.)
//@ Utilizes the 'validate' object from redux-forms to access form values
//@ This method can be globally applied to any form where this field is required

//*fname
export const validFirstName = (errorList, values, fieldName) => {
  if (!values[fieldName]) {
    return (errorList[fieldName] = "Required");
  }
};

//*lname
export const validLastName = (errorList, values, fieldName) => {
  if (!values[fieldName]) {
    return (errorList[fieldName] = "Required");
  }
};

/*
 *
 * Email Helper
 *
 * */

//@ 1st Param is the 'errors' object to which the flag should be attributed
//@ 2nd Param is a reference to the 'values' object which holds field values
//@ 3rd Param is the NAME of the selected field (In this case, email)
//@ Utilizes the 'validate' object from redux-forms to access form values
//@ This method can be globally applied to any form where this field is require

export const validEmail = (errorList, values, fieldName) => {
  let testEmail = new RegExp(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

  if (!values[fieldName]) {
    return (errorList[fieldName] =
      "We need an email address to send your pickup confirmation and reminders.");
  }

  if (testEmail.test(values[fieldName]) === false) {
    return (errorList[fieldName] =
      "Please check that your email address is formatted correctly.");
  }
};

/*
 *
 * Zipcode Helper
 *
 * */

export const validZipcode = (errorList, values, fieldName) => {
  if (!values[fieldName]) {
    return (errorList[fieldName] = "Please enter your zip code.");
  }

  if (values[fieldName].length > 5) {
    return (errorList[fieldName] = "Zip codes must be 5 digits.");
  }
};

/*
 *
 * State Helper
 *
 * */

export const validState = (errorList, values, fieldName) => {
  if (!values[fieldName]) {
    return (errorList[fieldName] = "Required");
  }

  if (values[fieldName].length > 2) {
    return (errorList[fieldName] = "Example: VA,TX, MD etc.");
  }
};

// Takes an array of pickupBlocks and returns an array of the type specified
export const getPickupBlockByType = (pickupBlocks = [], type) => {
  if (!Array.isArray(pickupBlocks)) {
    return false;
  }

  return pickupBlocks.filter((p) => p.zone.pickupTypeId === type.id);
};

const dateTimeFormat = "dddd - MM/DD/YYYY, hh:mm a";

export const formatTime = (time) => {
  return moment.utc(time).format(dateTimeFormat);
};

export const monthYear = (time) => {
  return moment.utc(time).format("MMMM YYYY");
};

export const getYear = (time) => {
  return moment.utc(time).format("gggg");
};

export const storeAuthToken = (authToken) => {
  localStorage["pickups.dt"] = authToken;
};

export const getAuthToken = () => {
  return localStorage["pickups.dt"];
};

export const logout = (userLoggingOut = false) => {
  delete localStorage["pickups.dt"];
  delete localStorage["p.u"];
};

export const loggedIn = () => {
  return !!localStorage["pickups.dt"];
};

const readablePickupStatus = (pickupObj) => {
  const { pickupStatus, routeId } = pickupObj;
  let statusText = "Unknown";

  if (pickupStatus.label === "Pending") {
    statusText = "Scheduled";

    if (routeId !== null) {
      statusText = "Assigned to a Route";
    }
  }

  if (pickupStatus.label === "Complete") {
    statusText = `Pickup Completed`;
  }

  if (pickupStatus.label === "Canceled") {
    statusText = `Pickup Canceled`;
  }

  return statusText;
};

const selectEstimatedTime = (pickupObj) => {
  const { statusId, estimatedArrivalTime, timeWindow } = pickupObj;
  if (statusId === 1) {
    if (timeWindow && timeWindow !== "Invalid date") {
      return timeWindow;
    }

    if (estimatedArrivalTime && estimatedArrivalTime !== "Invalid date") {
      return estimatedArrivalTime;
    }
  }
  return undefined;
};

export const displayPickup = (pickupObj) => ({
  ...pickupObj,
  statusText: readablePickupStatus(pickupObj),
  displayType: pickupObj.pickupType.label,
  pickupDate: moment.utc(pickupObj.pickupDate).format("MM/DD/YY (dddd)"),
  pickupDateNoDay: moment.utc(pickupObj.pickupDate).format("MMDDYY"),
  estimatedTime: selectEstimatedTime(pickupObj),
});

/**
 * Download a file from a URL.
 *
 * @param url The URL to the file to download.
 * @param filename The filename to save the file to.
 * @returns {Promise<void>}
 */
export const downloadFile = async (url, filename) => {
  const {
    request: { response },
  } = await request({ url, responseType: "blob" });

  const a = document.createElement("a");
  a.href = URL.createObjectURL(response);
  a.download = filename;
  a.click();
};

/**
 * Get the cropped image from react-image-crop
 *
 * @param {HTMLImageElement} image - Image File Object
 * @param {Object} crop - crop Object
 * @param {String} fileName - Name of the returned file in Promise
 */
export const getCroppedImg = (image, crop) => {
  const canvas = document.createElement("canvas");
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = crop.width;
  canvas.height = crop.height;
  const ctx = canvas.getContext("2d");

  // New lines to be added
  const pixelRatio = window.devicePixelRatio;
  canvas.width = crop.width * pixelRatio;
  canvas.height = crop.height * pixelRatio;
  ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
  ctx.imageSmoothingQuality = "high";

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height
  );

  // As a blob
  return new Promise((resolve, reject) => {
    canvas.toBlob(
      (blob) => {
        resolve(blob);
      },
      "image/png",
      1
    );
  });
};
